import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Hero from "../../components/hero"
import HeroIcons from "../../components/hero-icons"
import ContactFormVerificaChiamate from "../../components/contact-form-verifica-chiamate"


const VerificaChiamateRicevute = ({ data: { page, numeri }, location, pageContext: { langCode } }) => {
  const myPage = {
    pageTitle: {
      value: 'Verifica chiamate ricevute'
    }, 
    liferayParentPage: page,
    langCode
  };
  const myHero = {
    ...page.hero,
    imageTitle: {
      value: 'Verifica chiamate ricevute'
    }
  }
  const [csvUrl, setCsvUrl] = useState(null);
  useEffect(() => {
    if (numeri?.publicURL && !csvUrl) {
      const url = new URL(window.location.protocol + window.location.host + numeri.publicURL);
      url.search = '';
      setCsvUrl(url.toString());
    };
  }, [numeri, csvUrl]);
  return (
    <Layout langCode={langCode} location={location}>
      <Seo title={"Verifica chiamate ricevute"} langCode={langCode} 
        description={"Verifica chiamate ricevute"} robots={["all"]} 
        canonical={["https://www.engie.it/privacy/verifica-chiamate-ricevute/"]} />   
      {myHero ? (
        myHero.__typename === 'LiferaySiteHeader_Hero' ?
          <Hero data={myHero} page={myPage} /> : <HeroIcons data={myHero} page={myPage} />
      ) : null}
      <ContactFormVerificaChiamate csvUrl={csvUrl} />
    </Layout>
  )
};

export default VerificaChiamateRicevute;
export const verificaChiamateQuery = graphql`query VerificaChiamateQuery($langCode: String!){
  page: liferayPaginaprivacy(langCode: { eq: $langCode}) {
    langCode
    pageTitle {
      value
      content {
        skipEn
      }
    }
    htmlTitle {
      value
      content {
        metaDescription {
          value
        }
        canonicalUrl {
          value
        }
      }
      ...PrivacyTitleFragment
    }
    hero {
      __typename
      ...SiteHeader_Hero_IconeFragment
    }
  }
  numeri: liferayDocument(title: {eq: "numeri-engie.csv"}) {
    publicURL
  }
}`;